// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import filter from 'lodash/filter'
import endsWith from 'lodash/endsWith'
import isUndefined from 'lodash/isUndefined'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
// import { updateHeaderState } from '../../state/actions'
import Slider from './slider'

import {
  updateLightOrDarkMode as updateLightOrDarkModeFx,
  updateHeaderState,
} from '../../state/actions'

import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    logoDark: file(relativePath: { eq: "logos/logo-dark.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    logoLight: file(relativePath: { eq: "logos/logo-light.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    logoDarkDesktop: file(relativePath: { eq: "logos/logo-dark-desktop.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    logoLightDesktop: file(
      relativePath: { eq: "logos/logo-light-desktop.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    logoDarkMobile: file(relativePath: { eq: "logos/logo-dark-mobile.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Header */
class Header extends React.PureComponent {
  /** [constructor description] */
  constructor() {
    super()

    this.state = {
      isActive: false,
      whatsActive: 'root',
    }

    this.update = this.update.bind(this)
    this.linkRef = React.createRef()
  }

  /** [update description] */
  update(isActive, whatsActive) {
    this.setState({ isActive, whatsActive })
  }

  /** [render description] */
  render() {
    const {
      location,
      client,
      logoDarkMobile,
      logo,
      lightOrDarkMode,
      updateLightOrDarkMode,
      mediaState: { currentWidth = 'isDesktop' } = {},
      shoppingCartState: { totalCountOfObjects = 0 } = {},
      uri,
      edgesData,
    } = this.props
    const { isActive, whatsActive } = this.state
    const showSlider =
      currentWidth === 'isMobile' || currentWidth === 'isTablet'

    return (
      <StaticQuery
        query={query}
        render={(data) => (
          <Fragment>
            <header ref={this.linkRef}>
              <button
                type="button"
                title="Logo"
                className="logo"
                onClick={() => {
                  if (typeof window !== 'undefined') {
                    if (client === true) {
                      const newMode =
                        lightOrDarkMode === 'light' ? 'dark' : 'light'
                      updateLightOrDarkMode(newMode)
                    }
                  }
                }}
              >
                <GatsbyImage image={getImage(data.logoLightDesktop)} />
              </button>
              <nav style={{ display: showSlider === false ? 'flex' : 'none' }}>
                <li>
                  <Link activeClassName="active" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link activeClassName="active" to="/about">
                    About
                  </Link>
                </li>
                <li>
                  <Link activeClassName="active" to="/shop">
                    Shop
                  </Link>
                </li>
                <li>
                  <Link activeClassName="active" to="/cart">
                    Cart&nbsp;({totalCountOfObjects})
                  </Link>
                </li>
                <li>
                  <Link activeClassName="active" to="/contact">
                    Contact
                  </Link>
                </li>
              </nav>
              <Link
                onClick={(e) => {
                  e.preventDefault()
                  if (isActive === false) {
                    this.update(true, 'menu')
                  }
                  if (isActive === true) {
                    this.update(false, 'root')
                  }
                }}
                to="#"
                aria-label="Contents"
                style={{ display: showSlider === true ? 'block' : 'none' }}
              >
                <span>Menu</span>
              </Link>
            </header>
            <Slider
              isActive={isActive}
              whatsActive={whatsActive}
              update={this.update}
              linkRef={this.linkRef}
              totalCountOfObjects={totalCountOfObjects}
            />
          </Fragment>
        )}
      />
    )
  }
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    lightOrDarkMode: state.lightOrDarkMode,
    mediaState: state.mediaState,
    shoppingCartState: state.shoppingCartState,
  }),
  (dispatch) => ({
    updateLightOrDarkMode(payload) {
      dispatch(updateLightOrDarkModeFx(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedHeader = compose(
  withState // Add state
)(Header)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedHeader
