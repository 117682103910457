// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Footer */
const Footer = ({ shoppingCartState }) => (
  <footer>
    <div className="options">
      <ul style={{ textAlign: 'left' }}>
        <li>
          <Link to="https://goo.gl/maps/hMeN1sU6ZMXiAobv5">
            LiGHT-FiSH, Aurelec, Auroville 605101, India
          </Link>
        </li>
        <li>
          <Link to="tel:+914132622979">+91-413-2622979</Link>
        </li>
        <li>
          <Link to="mailto:info@light-fish.com">info@light-fish.com</Link>
        </li>
      </ul>
      <ul style={{ textAlign: 'right' }}>
        <li>
          <Link to="/shop">Shop</Link>
        </li>
        <li>
          <Link to="https://www.facebook.com/LiGHTFiSHTALK/">Facebook</Link>
        </li>
        <li>
          <Link to="https://www.instagram.com/light_fish_talk/">Instagram</Link>
        </li>
      </ul>
    </div>
    <div
      style={{
        paddingTop: '0.62rem',
        paddingBottom: '0.62rem',
      }}
    >
      <p
        style={{
          textAlign: 'center',
          margin: 'unset',
          maxWidth: 'unset',
          color: 'var(--text-color)',
        }}
      >
        <small>
          <Link to="https://www.prisma.haus">
            Copyright © 2010–2021 LiGHT-FiSH
          </Link>
        </small>
        <br />
        <small>
          <Link to="https://seeddesigns.in/">
            Design inputs for website by Seed Designs
          </Link>
        </small>
        <br />
        <small>
          <Link to="https://design.prisma.haus">
            Website handcrafted by Prisma Design
          </Link>
        </small>
      </p>
    </div>
  </footer>
)

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    lightOrDarkMode: state.lightOrDarkMode,
    mediaState: state.mediaState,
    shoppingCartState: state.shoppingCartState,
  }),
  (dispatch) => ({
    // updateLightOrDarkMode(payload) {
    //   dispatch(updateLightOrDarkModeFx(payload))
    // },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedFooter = compose(
  withState // Add state
)(Footer)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedFooter
