// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'
import { useMediaQuery } from 'react-responsive'

import ConfigProvider from 'antd/lib/config-provider'
import '@bodhi-project/antrd/lib/4.10.3/config-provider/style/css'

import InitializeMeta from '@bodhi-project/seo/lib/InitializeMeta'
import UpdateTitle from '@bodhi-project/seo/lib/UpdateTitle'
import WebsiteSchema from '@bodhi-project/seo/lib/WebsiteSchema'
import OrganisationSchema from '@bodhi-project/seo/lib/OrganisationSchema'

import '@bodhi-project/antrd/lib/4.10.3/style/index.css'

import MediaState from '@bodhi-project/components/lib/media-state'

import getRandomArbitraryInt from '@bodhi-project/components/lib/methods/getRandomArbitraryInt'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Header from '../header'
import '../header/style.less'

import Footer from '../footer'
import '../footer/style.less'

import MessageBus from '../message-bus'
import '../message-bus/style.less'

import indexImage from '../../images/banners/launch.jpg'
import data from '../../data/website.json'

import '../../styles/index.less'
import '../link/style.less'
import './style.less'

import '../article-listing/style.less'
import '../contact-form/style.less'
import '../product/variants-table/style.less'
// import '../product-page/style.less'

import {
  updateLightOrDarkMode as updateLightOrDarkModeFx,
  updateMediaState,
  updateScreenSizeState,
} from '../../state/actions'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const exportImageFragments = graphql`
  fragment max90 on File {
    childImageSharp {
      fluid(maxWidth: 90, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment max300 on File {
    childImageSharp {
      fluid(
        maxWidth: 300
        quality: 80
        srcSetBreakpoints: [100, 150, 200, 250, 300]
      ) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment max600 on File {
    childImageSharp {
      fluid(
        maxWidth: 300
        quality: 80
        srcSetBreakpoints: [100, 150, 200, 250, 300, 450, 600]
      ) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment max900 on File {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80, srcSetBreakpoints: [300, 600, 900]) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment defaultImage on File {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80, srcSetBreakpoints: [300, 600, 900]) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment max1200 on File {
    childImageSharp {
      fluid(
        maxWidth: 1200
        quality: 80
        srcSetBreakpoints: [300, 600, 900, 1200]
      ) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment max1600 on File {
    childImageSharp {
      fluid(
        maxWidth: 1600
        quality: 80
        srcSetBreakpoints: [300, 600, 900, 1200, 1600]
      ) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`
export const query = graphql`
  query {
    logoLight: file(relativePath: { eq: "logos/logo-light.png" }) {
      ...max1600
    }
    logoDark: file(relativePath: { eq: "logos/logo-dark-desktop.png" }) {
      ...max1600
    }
    logoDarkMobile: file(relativePath: { eq: "logos/logo-dark-mobile.png" }) {
      ...max1600
    }
    facebookLogo: file(relativePath: { eq: "logos/facebook-logo.png" }) {
      ...max1600
    }
    youtubeLogo: file(relativePath: { eq: "logos/youtube-logo.png" }) {
      ...max1600
    }
    instagramLogo: file(relativePath: { eq: "logos/instagram-logo.png" }) {
      ...max1600
    }
    stretch: file(relativePath: { eq: "contact/img1.jpg" }) {
      ...max1600
    }
  }
`

// ----------------------------------------------------------------------------
// ----------------------------------------------------------------- Global SEO
// ----------------------------------------------------------------------------
const websiteSchemaData = {
  url: data.websiteUrl,
  name: data.websiteName,
  description: data.websiteDescription,
  author: data.org.name,
  publisher: data.org.name,
  image: indexImage,
}

const organisationSchemaData = {
  name: data.org.name,
  legalName: data.org.legalName,
  url: data.org.url,
  logo: `${data.org.url}${data.org.logo}`,
  foundingDate: new Date(data.org.foundingDate).toISOString(),
  founders: data.org.founders,
  streetAddress: data.orgLocation.streetAddress,
  addressLocality: data.orgLocation.addressLocality,
  addressRegion: data.orgLocation.addressRegion,
  postalCode: data.orgLocation.postalCode,
  addressCountry: data.orgLocation.addressCountry,
  telephone: data.orgContact.telephone,
  email: data.orgContact.email,
  sameAs: data.orgSocialMediaProfiles,
  image: indexImage,
}

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Layout */
class Layout extends React.Component {
  /** standard constructor */
  constructor(props) {
    super(props)

    this.state = {
      client: false,
    }
  }

  /** after mount */
  componentDidMount() {
    this.setState({ client: true })

    if (typeof window !== 'undefined') {
      const element = document.getElementById('layout')
      element.scrollTop = 0

      const { lightOrDarkMode } = this.props
      const html = document.getElementById('copy')

      if (lightOrDarkMode === 'light') {
        if (html.classList.contains('light') === false) {
          html.classList.remove('dark')
          html.classList.add('light')
        }
      }

      if (lightOrDarkMode === 'dark') {
        if (html.classList.contains('dark') === false) {
          html.classList.remove('light')
          html.classList.add('dark')
        }
      }
    }
  }

  /** on mount */
  componentDidUpdate() {
    if (typeof window !== 'undefined') {
      if (this.state.client === true) {
        const element = document.getElementById('layout')
        element.scrollTop = 0

        const { lightOrDarkMode } = this.props
        const html = document.getElementById('copy')

        if (lightOrDarkMode === 'light') {
          if (html.classList.contains('light') === false) {
            html.classList.remove('dark')
            html.classList.add('light')
          }
        }

        if (lightOrDarkMode === 'dark') {
          if (html.classList.contains('dark') === false) {
            html.classList.remove('light')
            html.classList.add('dark')
          }
        }
      }
    }
  }

  /** standard renderer */
  render() {
    const {
      location,
      children,
      uri = '',
      lightOrDarkMode,
      updateLightOrDarkMode,
      mediaState,
      screenSizeState,
      updateMediaState,
      updateScreenSizeState,
      className = '',
    } = this.props
    const { pathname } = location
    const { client } = this.state

    return (
      <ConfigProvider prefixCls="wtf">
        <MediaState
          className={`${className} container layout`}
          id="layout"
          data-uri={uri}
          useMediaQuery={useMediaQuery}
          mediaState={mediaState}
          updateMediaState={updateMediaState}
          useEffect={useEffect}
          useState={useState}
          client={client}
        >
          <InitializeMeta
            data={{ titleTemplate: `%s | ${data.websiteName}` }}
          />
          <UpdateTitle title={data.websiteAbstract} />
          <WebsiteSchema data={websiteSchemaData} />
          <OrganisationSchema data={organisationSchemaData} />
          <StaticQuery
            query={query}
            render={(data) => {
              const { logoDark, logoDarkMobile } = data

              return (
                <Fragment>
                  <Header
                    location={location}
                    client={client}
                    logoDarkMobile={logoDarkMobile.childImageSharp.fluid}
                    logo={logoDark.childImageSharp.fluid}
                  />
                  <main role="main">{children}</main>
                  <Footer />
                  <div className="stretch" style={{ display: 'none' }}>
                    <Img
                      fluid={data.stretch.childImageSharp.fluid}
                      durationFadeIn={getRandomArbitraryInt(300, 1200)}
                    />
                  </div>
                </Fragment>
              )
            }}
          />
        </MediaState>
        <MessageBus />
      </ConfigProvider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    lightOrDarkMode: state.lightOrDarkMode,
    mediaState: state.mediaState,
    screenSizeState: state.screenSizeState,
  }),
  (dispatch) => ({
    updateMediaState(payload) {
      dispatch(updateMediaState(payload))
    },
    updateScreenSizeState(payload) {
      dispatch(updateScreenSizeState(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedLayout = compose(
  withState // Add state
)(Layout)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedLayout
